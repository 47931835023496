import styled from 'styled-components';

export const ForaEsta = styled.section`
  width: 1500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 150px;

  @media (max-width: 1550px) {
    width: 1050px;
  }

  @media (max-width: 1100px) {
    width: 800px;
  }

  @media (max-width: 850px) {
    flex-wrap: wrap;
    width: 90%;
    padding-top: 30px;
  }
`;

export const EsqEstac = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 850px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const TextEsta1 = styled.h1`
  font-family: 'Rubik', sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #3b3b3b;
  text-transform: uppercase;
  text-align: right;

  @media (max-width: 1100px) {
    font-size: 41px;
  }

  @media (max-width: 540px) {
    font-size: 32px;
  }

  @media (max-width: 420px) {
    font-size: 25px;
  }
`;

export const TextEsta1Baixo = styled.h1`
  font-family: 'Rubik', sans-serif;
  font-size: 47px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.secondary};
  text-transform: uppercase;
  text-align: right;
  margin-top: -8px;

  @media (max-width: 1100px) {
    font-size: 41px;
  }

  @media (max-width: 540px) {
    font-size: 32px;
  }

  @media (max-width: 420px) {
    font-size: 25px;
    margin-top: -1px;
  }
`;

export const ForaItensEsta = styled.div`
  width: 412px;
  margin-top: 17px;

  @media (max-width: 1100px) {
    width: 347px;
  }

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (max-width: 420px) {
    margin-top: 0px;
  }
`;

export const ItemEsta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  @media (max-width: 850px) {
    justify-content: flex-start;
  }
`;

export const EsqItemEsta = styled.div`
  @media (max-width: 850px) {
    padding-left: 20px;
    width: 74%;
  }
`;

export const TextItemEsta = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black2};
  text-align: right;

  @media (max-width: 850px) {
    text-align: left;
  }

  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

export const TextItemEsta2 = styled.h4`
  font-size: 15px;
  font-weight: 400;
  color: #3d3d3d;
  text-align: right;

  @media (max-width: 850px) {
    text-align: left;
  }
`;

export const TextDirItemEsta = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  background-color: #3b3b3b;
  width: 50px;
  height: 50px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  @media (max-width: 850px) {
    display: none;
  }
`;

export const TextDirItemEstaResp = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  background-color: #3b3b3b;
  width: 50px;
  height: 50px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  @media (max-width: 850px) {
    display: flex;
  }

  @media (max-width: 420px) {
    width: 40px;
    height: 40px;
    font-size: 21px;
  }
`;

export const DirEsta = styled.div`
  width: 58%;

  @media (max-width: 1550px) {
    width: 41%;
  }

  @media (max-width: 1100px) {
    width: 56%;
    padding-left: 4%;
  }

  @media (max-width: 850px) {
    margin: 0 auto;
    margin-top: 30px;
    width: 100%;
    padding-left: 0%;
  }
`;

export const ImagemEstacionamento = styled.img`
  width: 100%;
  height: auto;
`;

export const ImagemMapa = styled.img`
  width: 100%;
  margin-top: -5px;

  @media (max-width: 850px) {
    display: none;
  }
`;

export const ImagemMapaResp = styled.img`
  width: 100%;
  margin-top: -5px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`;
