import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './estilos';

import ImgFooter1 from '../../Images/ImgFooter1.png';
import ImgFooter2 from '../../Images/ImgFooter2.png';
import ImgFooter3 from '../../Images/ImgFooter3.png';
import ImgFooter4 from '../../Images/ImgFooter4.png';
import IconeLab from '../../Images/IconeLab.png';

function Footer() {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();

  const MandaSpEventos = () => {
    window.open('http://www.gruposaopauloeventos.com.br/', '_blank');
  };

  const MandaVila = () => {
    window.open('http://www.gruposaopauloeventos.com.br/villacountry/', '_blank');
  };

  const MandaExpo = () => {
    window.open('https://expobarrafunda.com.br/', '_blank');
  };

  const MandaUnimed = () => {
    window.open('http://www.gruposaopauloeventos.com.br/espacounimed/', '_blank');
  };

  return (
    <>
      <S.BgFooter>
        <S.ForaFooter>
          <S.TextFooter1>Grupo São Paulo Eventos</S.TextFooter1>
          <S.ForaImagensFooter>
            <S.ForaImgFooter onClick={() => MandaVila()}>
              <S.ImagemFooter src={ImgFooter2} />
            </S.ForaImgFooter>
            <S.ForaImgFooter onClick={() => MandaExpo()}>
              <S.ImagemFooter src={ImgFooter3} />
            </S.ForaImgFooter>
            <S.ForaImgFooter onClick={() => MandaUnimed()}>
              <S.ImagemFooter src={ImgFooter4} />
            </S.ForaImgFooter>
          </S.ForaImagensFooter>
          <S.TextoPolitica onClick={() => history.push('/PoliticadePrivacidade')}>
            Política de Privacidade
          </S.TextoPolitica>
        </S.ForaFooter>
      </S.BgFooter>
      <S.BaixoFooter>
        <S.ForaBaixoFooter>
          <S.TextBaixoFooter>Powered by</S.TextBaixoFooter>
          <S.ForaImgFooter>
            <S.ImagemFooter src={IconeLab} />
          </S.ForaImgFooter>
        </S.ForaBaixoFooter>
      </S.BaixoFooter>
    </>
  );
}

export default Footer;
