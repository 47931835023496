import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

const Alert = (children: JSX.Element | JSX.Element[]) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <div className='AlertaJs'>
            <div style={{ paddingBottom: '10px' }}>{children}</div>
            <button
              className='BotaoAcessarBD'
              onClick={() => {
                onClose();
              }}>
              FECHAR
            </button>
          </div>
        </div>
      );
    },
  });
};

export default Alert;
