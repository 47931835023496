import styled from 'styled-components';
// import ImgLogo from "./Images/seta_baixo.png";

export const FundoTopo = styled.header`
  background-color: ${({ theme }) => theme.color.black};
  position: fixed;
  width: 100%;
  z-index: 2;
`;

export const ForaTopo = styled.div`
  display: flex;
  width: 1600px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1640px) {
    width: 1270px;
  }

  @media (max-width: 1320px) {
    width: 1000px;
    height: 76px;
  }

  @media (max-width: 1045px) {
    width: 90%;
    height: 90px;
  }
`;

export const LogoTopo = styled.img`
  width: 205px;
  margin-bottom: -76px;
  z-index: 1;

  @media (max-width: 1640px) {
    width: 165px;
    margin-bottom: -45px;
  }

  @media (max-width: 1320px) {
    width: 120px;
    margin-bottom: -42px;
  }

  @media (max-width: 1045px) {
    width: 175px;
    margin-bottom: -82px;
  }

  @media (max-width: 630px) {
    width: 93px;
    margin-bottom: 0px;
  }  
`;

export const ForaMenu = styled.nav`
  width: 70%;
  display: flex;
  justify-content: space-between;


  @media (max-width: 1045px) {
    display: none;
  }
`;

export const TextMenu = styled.a`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.color.secondary};
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1640px) {
    font-size: 15px;
  }

  @media (max-width: 1320px) {
    font-size: 13px;
  }
`;

export const DirTopo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1045px) {
    width: 230px;
  }  
  
  @media (max-width: 450px) {
    width: 215px;
  }
`;

export const OutTel = styled.div`
  display: flex;
  width: 202px;
  justify-content: space-between;

  @media (max-width: 1640px) {
    width: 175px;
  }  
  
  @media (max-width: 1320px) {
    width: 145px;
  }
`;

export const ImagemIconeInsta1Whats = styled.img`
  width: 25px;
  margin-top: 3px;

`;

export const OutIconsInsta = styled.div`
  display: flex;
  width: 75px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1640px) {
    flex-direction: column;
    width: 32px;
  }

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const ImagemIconeInsta = styled.img`
  width: 30px;

  @media (max-width: 1320px) {
    width: 25px;
  }

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const ImagemIconeInsta1 = styled.img`
  width: 35px;

  @media (max-width: 1320px) {
    width: 25px;
  }

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const ImagemIconeInsta2 = styled.img`
  display: none;
  margin: 0 auto;
  margin-bottom: 14px;
  width: 30px;

  @media (max-width: 1045px) {
    display: block;
  }
`;

export const ImagemIconeInsta3 = styled.img`
  display: none;
  margin: 0 auto;
  margin-bottom: 14px;
  width: 35px;

  @media (max-width: 1045px) {
    display: block;
  }
`;

export const ItemTelefone = styled.div`

`;

export const TextTel1 = styled.h2`
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 1640px) {
    font-size: 20px;
  }

  @media (max-width: 1320px) {
    font-size: 16px;
  }
`;

export const TextTel2 = styled.h3`
  font-weight: 400;
  font-size: 10px;
  color: #919191;
  text-transform: uppercase;
  text-align: right;

  @media (max-width: 1320px) {
    font-size: 9px;
  }
`;

export const TextOrcamento = styled.h3`
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.color.primary};
  height: 40px;
  padding: 0px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 1640px) {
    font-size: 14px;
    padding: 0px 8px;
  }

  @media (max-width: 1320px) {
    font-size: 12px;
    padding: 0px 4px;
  }

  @media (max-width: 470px) {
    font-size: 13px;
    padding: 0px 5px;
  }

  @media (max-width: 420px) {
    display: none;
  }
`;

export const ForaBotaoResp = styled.div`
  display: none;
  width: 60px;
  cursor: pointer;

  @media (max-width: 1045px) {
    display: block;
  }

  @media (max-width: 470px) {
    width: 50px;
  }
`;

export const RiscoBotaoResp = styled.div`
  width: 100%;
  background-color: #fff;
  height: 4px;
  border-radius: 2px;

  :nth-child(2),
  :nth-child(3) {
    margin-top: 10px;
  }

  @media (max-width: 470px) {
    :nth-child(2),
    :nth-child(3) {
      margin-top: 10px;
    }
  }
`;

export const FundoMenuMobile = styled.div``;

export const ForaMenuMobile = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 100;
`;

export const CimaMenuMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  @media (max-width: 420px) {
    margin-top: 10px;
  }
`;

export const ImagemMenuMobile = styled.img`
  width: 140px;
`;

export const XMenuMobile = styled.div`
  font-weight: 500;
  font-size: 27px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 21px;
  color: #fff;

  @media (max-width: 420px) {
    top: 4px;
  }
`;

export const TextMenuMobile1 = styled.div`
  font-weight: bold;
  font-size: 28px;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  text-align: center;
  width: 100%;

  @media (max-width: 420px) {
    font-size: 22px;
  }
`;

export const ItensMenuMobile = styled.div`
  margin-top: 20px;

  @media (max-width: 420px) {
    margin-top: 5px;
  }
`;

export const TextMenuMobile = styled.div`
  font-weight: 500;
  font-size: 25px;
  padding-top: 0px;
  padding-bottom: 12px;
  text-align: center;
`;

export const TelefoneMenuMobile = styled.div`
  font-weight: bold;
  font-size: 47px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
`;

export const TelefoneMenuMobile2 = styled.div`
  font-weight: 500;
  font-size: 19px;
  padding-top: 0px;
  padding-bottom: 12px;
  text-align: center;
  text-transform: uppercase;
`;

export const ForaBaixoMenuMobile = styled.div`
  background-color: #212121;
  padding-top: 15px;
  margin-top: 20px;
  height: 58vw;
`;

export const OutIconMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 105px;
  margin: 0 auto;
`;

export const TextGrupoResp = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  margin-top: 30px;
`;

export const ForaImagensResp = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  margin-top: 20px;
`;

export const ItemImg = styled.div`
  width: 25%;
`;

export const ImgGrupo = styled.img`
  width: 100%;
  height: auto;
`;

export const BotaoSoliciteResp = styled.div`
  font-weight: bold;
  background-color: ${({ theme }) => theme.color.primary};
  width: 290px;
  border-radius: 4px;
  font-size: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
  margin: 0 auto;
`;

export const FundoSolicite = styled.div`
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 100;
  background-color: rgb(20, 20, 20, 0.99);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const XSolicite = styled.div`
  position: absolute;
  top: 25px;
  right: 35px;
  font-weight: bold;
  font-size: 32px;
  cursor: pointer;
`;

export const ForaSolicite = styled.div`
  width: 800px;
  background-color: #fff;
  border-radius: 12px;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const ItemSolicite = styled.div``;

export const TextPopSolicite1 = styled.div`
  color: #01995c;
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 485px) {
    width: 222px;
    margin: 0 auto;
  }
`;

export const BotaoEnviarSolicite = styled.div`
  width: 240px;
  background-color: #01995c;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 15px;
`;

export const TextValidation = styled.span`
  /* font-weight: ; */
  font-size: 12px;
  padding-top: 5px;
  padding-left: 5px;
  color: ${({ theme }) => theme.color.primary};
`;
