/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as S from './estilos';
import { Alert, WhatsApp } from '../atoms';
import Logo from '../../Images/Logo.jpg';
import ImgFooter1 from '../../Images/ImgFooter1.png';
import ImgFooter2 from '../../Images/ImgFooter2.png';
import ImgFooter3 from '../../Images/ImgFooter3.png';
import ImgFooter4 from '../../Images/ImgFooter4.png';

import BtWhats from '../../Images/whatsapp.png';
import useData from '../../Utils/useData';
import Load from '../Load';

function Header(props: any) {
  const history = useHistory();

  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState('');
  const [nomeValida, setNomeValida] = useState(false);
  const [empresa, setEmpresa] = useState('');
  const [empresaValida, setEmpresaValida] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [telefoneValida, setTelefoneValida] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValida, setEmailValida] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [mensagemValida, setMensagemValida] = useState(false);
  const [whats, setWhats] = useState(false);

  const [data, setData] = useState<any>([]);

  const [menuMobile, setMenuMobile] = useState(false);
  const [orcamento, setOrcamento] = useState(false);

  const MandaSpEventos = () => {
    window.open('http://www.gruposaopauloeventos.com.br/', '_blank');
  };

  const MandaVila = () => {
    window.open('http://www.gruposaopauloeventos.com.br/villacountry/', '_blank');
  };

  const MandaExpo = () => {
    window.open('https://expobarrafunda.com.br/', '_blank');
  };

  const MandaUnimed = () => {
    window.open('http://www.gruposaopauloeventos.com.br/espacounimed/', '_blank');
  };

  const MandaCasa = () => {
    setMenuMobile(false);
    history.push('/#Casa');
  };

  const MandaHome = () => {
    setMenuMobile(false);
    history.push('/#Home');
  };

  const MandaBuffet = () => {
    setMenuMobile(false);
    history.push('/#Buffet');
  };

  const MandaEstacionamento = () => {
    setMenuMobile(false);
    history.push('/#Estacionamento');
  };

  const MandaLocalizacao = () => {
    setMenuMobile(false);
    history.push('/#Localizacao');
  };

  const MandaEventos = () => {
    setMenuMobile(false);
    history.push('/#Eventos');
  };

  const MandaContato = () => {
    setMenuMobile(false);
    history.push('/#Contato');
  };

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: 'smooth' });
    }
    // }, []);
  }, [props.history.location.hash]);

  const loadData = () => {
    useData()
      .getData('/api/whats.php')
      .then((response: any) => {
        setData(response[0].c_whats);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      loadData();
    }, 1000);
  }, []);

  const clearFields = () => {
    setNome('');
    setEmpresa('');
    setTelefone('');
    setEmail('');
    setMensagem('');
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: '10px' }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;
    if (nome === '' || nome === undefined) {
      setNomeValida(true);
      validaMenssages.push(<div key={'Nome'}>- Nome</div>);
      valida = true;
    } else {
      setNomeValida(false);
    }
    if (empresa === '' || empresa === undefined) {
      setEmpresaValida(true);
      validaMenssages.push(<div key={'Empresa'}>- Empresa</div>);
      valida = true;
    } else {
      setEmpresaValida(false);
    }
    if (telefone === '' || telefone === undefined) {
      setTelefoneValida(true);
      validaMenssages.push(<div key={'Telefone'}>- Telefone</div>);
      valida = true;
    } else {
      setTelefoneValida(false);
    }

    if (email === '' || email === undefined) {
      setEmailValida(true);
      validaMenssages.push(<div key={'E-mail'}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        setEmailValida(false);
        null;
      } else {
        validaMenssages.push(<div key={'E-mail'}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === '' || mensagem === undefined) {
      setMensagemValida(true);
      validaMenssages.push(<div key={'Mensagem'}>- Mensagem</div>);
      valida = true;
    } else {
      setMensagemValida(false);
    }
    if (valida) {
      // Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const data = JSON.stringify({
        nome: nome,
        empresa: empresa,
        telefone: telefone,
        email: email,
        mensagem: mensagem,
      });
      setLoad(true);
      useData()
        .postData('/api/contato.php', data)
        .then((response) => {
          console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          setLoad(false);
          setOrcamento(false);
        })
        .catch((err) => {
          setLoad(false);
          console.info(err);
        });
    }
  };

  return (
    <>
      {data == 1 && <a href='https://wa.me/5511916220748' target='_blank' rel='noreferrer' className='BtWhatsapp'></a>}
      <Load status={load} colorPrimary={'#c73127'} colorSecondary={'#b9b9b9'} />
      <S.FundoTopo>
        <S.ForaTopo>
          <S.LogoTopo src={Logo} />
          <S.ForaMenu>
            <S.TextMenu onClick={() => MandaHome()}>Home</S.TextMenu>
            <S.TextMenu onClick={() => MandaExpo()}>Expo barrafunda</S.TextMenu>
            <S.TextMenu onClick={() => MandaUnimed()}>Espaço unimed</S.TextMenu>
            <S.TextMenu onClick={() => MandaVila()}>Villa country</S.TextMenu>
            <S.TextMenu onClick={() => MandaEstacionamento()}>Estacionamento</S.TextMenu>
            <S.TextMenu onClick={() => MandaContato()}>Contato</S.TextMenu>
          </S.ForaMenu>
          <S.DirTopo>
            <S.OutTel>
              <a className='LinkInsta' target='_blank' href='https://wa.me/5511916220748' rel='noreferrer'>
                <S.ImagemIconeInsta1Whats src={BtWhats} />
              </a>
              <S.ItemTelefone>
                <S.TextTel1>11 91622-0748</S.TextTel1>
                <S.TextTel2> Seg à Sex das 9h às 19h</S.TextTel2>
              </S.ItemTelefone>
            </S.OutTel>
            <S.ForaBotaoResp onClick={() => setMenuMobile(true)}>
              <S.RiscoBotaoResp />
              <S.RiscoBotaoResp />
              <S.RiscoBotaoResp />
            </S.ForaBotaoResp>
          </S.DirTopo>
        </S.ForaTopo>
      </S.FundoTopo>

      {menuMobile == true && (
        <S.FundoMenuMobile>
          <S.ForaMenuMobile>
            <S.XMenuMobile onClick={() => setMenuMobile(false)}>X</S.XMenuMobile>
            <S.CimaMenuMobile>
              <S.TextMenuMobile1>São Paulo Eventos</S.TextMenuMobile1>
            </S.CimaMenuMobile>
            <S.ItensMenuMobile>
              <S.TextMenuMobile onClick={() => MandaHome()}>HOME</S.TextMenuMobile>
              <S.TextMenuMobile onClick={() => MandaExpo()}>Expo barrafunda</S.TextMenuMobile>
              <S.TextMenuMobile onClick={() => MandaUnimed()}>Espaço unimed</S.TextMenuMobile>
              <S.TextMenuMobile onClick={() => MandaVila()}>Villa country</S.TextMenuMobile>
              <S.TextMenuMobile onClick={() => MandaEstacionamento()}>Estacionamento</S.TextMenuMobile>
              <S.TextMenuMobile onClick={() => MandaContato()}>Contato</S.TextMenuMobile>
            </S.ItensMenuMobile>
            <S.TelefoneMenuMobile>11 91622-0748</S.TelefoneMenuMobile>
            <S.TelefoneMenuMobile2>Seg à Sex das 9h às 19h</S.TelefoneMenuMobile2>

            <S.ForaBaixoMenuMobile>
              <S.TextGrupoResp>Grupo São Paulo Eventos</S.TextGrupoResp>
              <S.ForaImagensResp>
                <S.ItemImg onClick={() => MandaSpEventos()}>
                  <S.ImgGrupo src={ImgFooter1} />
                </S.ItemImg>
                <S.ItemImg onClick={() => MandaVila()}>
                  <S.ImgGrupo src={ImgFooter2} />
                </S.ItemImg>
                <S.ItemImg onClick={() => MandaExpo()}>
                  <S.ImgGrupo src={ImgFooter3} />
                </S.ItemImg>
                <S.ItemImg onClick={() => MandaUnimed()}>
                  <S.ImgGrupo src={ImgFooter4} />
                </S.ItemImg>
              </S.ForaImagensResp>
            </S.ForaBaixoMenuMobile>
          </S.ForaMenuMobile>
        </S.FundoMenuMobile>
      )}

      {orcamento && (
        <S.FundoSolicite>
          <S.XSolicite onClick={() => setOrcamento(false)}>X</S.XSolicite>
          <S.ForaSolicite>
            <S.TextPopSolicite1>SOLICITE ORÇAMENTO</S.TextPopSolicite1>
            <div className='FormSolicite'>
              <input
                type='text'
                className='InputSolicita'
                placeholder='NOME *'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              {nomeValida && <S.TextValidation>Nome é obrigatório</S.TextValidation>}
              <input
                type='text'
                className='InputSolicita'
                placeholder='EMPRESA *'
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
              />
              {empresaValida && <S.TextValidation>Empresa é obrigatório</S.TextValidation>}
              <input
                type='text'
                className='InputSolicita'
                placeholder='E-MAIL *'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailValida && <S.TextValidation>E-mail é obrigatório</S.TextValidation>}
              <input
                type='text'
                className='InputSolicita'
                placeholder='TELEFONE *'
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
              {telefoneValida && <S.TextValidation>Telefone é obrigatório</S.TextValidation>}
              <textarea
                rows={2}
                placeholder='MENSAGEM *'
                className='InputSolicita'
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}></textarea>
              {mensagemValida && <S.TextValidation>Mensagem é obrigatório</S.TextValidation>}
              <S.BotaoEnviarSolicite onClick={() => onSubmit()}>ENVIAR</S.BotaoEnviarSolicite>
            </div>
          </S.ForaSolicite>
        </S.FundoSolicite>
      )}

      <WhatsApp isVisible={whats} number='5511999843012' />
    </>
  );
}

export default withRouter(Header);
