import React, { useEffect, useState } from 'react';

import { Header, Footer, Politica } from '../../Componentes';

import Load from '../../Componentes/Load';
import * as S from './estilos';
import { Oval } from 'react-loader-spinner';

import ImgBanner from '../../Images/Banner.jpg';
import ImgBannerResp from '../../Images/BannerResp.jpg';
import 'react-confirm-alert/src/react-confirm-alert.css';

function PoliticadePrivacidade(props: any) {
  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: 'smooth' });
    }
    // }, []);
  }, [props.history.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerData = [
    {
      normal: ImgBanner,
      responsive: ImgBannerResp,
      link: 'http://www.uol.com.br',
    },
    {
      normal: ImgBanner,
      responsive: ImgBannerResp,
      link: 'http://www.uol.com.br',
    },
    {
      normal: ImgBanner,
      responsive: ImgBannerResp,
      link: 'http://www.uol.com.br',
    },
  ];

  return (
    <>
      <link rel='stylesheet' href='fontes/stylesheet.css' />
      {/* <Load status={load} /> */}
      <Header />
      <Politica />
      <Footer />
    </>
  );
}

export default PoliticadePrivacidade;
