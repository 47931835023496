import styled from 'styled-components';
// import ImgBgRodape from "../../Images/BgRodape.jpg";

export const ForaSpiner = styled.div`
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
`;

