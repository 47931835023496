const Theme = {
  color: {
    primary: '#01995c',
    secondary: '#b9b9b9',
    black: '#141414',
    black2: '#3d3d3d',
  },

  // border: {
  //   radius: "15px",
  //   style: "2px solid #736681",
  // },

  // font: {
  //   small: "0.5rem",
  //   default: "1rem",
  //   big: "2rem",
  //   bigger: "3rem",
  // },
};

export default Theme;
