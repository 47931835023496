import styled from 'styled-components';
import BgBanner1 from '../../Images/BgBanner1.jpg';
import BgBanner2 from '../../Images/BgBanner2.jpg';
import BgBanner3 from '../../Images/BgBanner3.jpg';

export const OutBanner = styled.div`
  display: flex;
  padding-top: 125px;

  @media (max-width: 1640px) {
    padding-top: 117px;
  }

  @media (max-width: 1320px) {
    padding-top: 76px;
  }

  @media (max-width: 1045px) {
    padding-top: 90px;
  }

  @media (max-width: 630px) {
    flex-direction: column;
  }
`;

export const OutItemBanner = styled.div`
  width: 100%;
  height: 46vw;
  padding-top: 14vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  :hover .ForaBotoes {
    display: block;
  }

  @media (max-width: 1380px) {
    height: 52vw;
  }

  @media (max-width: 850px) {
    height: 60vw;
  }

  @media (max-width: 630px) {
    height: 45vw;
    flex-direction: row;
    padding-top: 0vw;
    justify-content: center;
  }
`;

export const ItemBanner = styled.div`
  background-image: url(${BgBanner1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 33.333%;
  height: 46vw;

  @media (max-width: 1380px) {
    height: 52vw;
  }

  @media (max-width: 850px) {
    height: 60vw;
  }

  @media (max-width: 630px) {
    height: 45vw;
    width: 100%;
  }
`;

export const ItemBanner2 = styled.div`
  background-image: url(${BgBanner2});
  background-size: cover;
  background-repeat: no-repeat;
  width: 33.333%;
  height: 46vw;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1380px) {
    height: 52vw;
  }

  @media (max-width: 850px) {
    height: 60vw;
  }

  @media (max-width: 630px) {
    height: 45vw;
    width: 100%;
  }
`;

export const ItemBanner3 = styled.div`
  background-image: url(${BgBanner3});
  background-size: cover;
  background-repeat: no-repeat;
  width: 33.333%;
  height: 46vw;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1380px) {
    height: 52vw;
  }

  @media (max-width: 850px) {
    height: 60vw;
  }

  @media (max-width: 630px) {
    height: 45vw;
    width: 100%;
  }
`;

export const LogoBanner = styled.div`
  width: 200px;
  height: 140px;
  align-items: center;
  display: flex;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 850px) {
    width: 160px;
  }

  @media (max-width: 630px) {
    width: 140px;
  }
`;

export const ButtonBanner = styled.div`
  font-size: 22px;
  height: 35px;
  text-transform: uppercase;
  border-radius: 12px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;

  :hover {
    background-color: #fff;
    color: #cb3831;
  }

  @media (max-width: 630px) {
    font-size: 22px;
    height: 40px;
  }
`;
