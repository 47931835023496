import React, { useEffect, useState } from 'react';
import * as S from './estilos';
import { Alert } from '../atoms';
import Load from '../Load';
import ImagemRiscoContato from '../../Images/ImagemRiscoContato.png';
import IconeContato1 from '../../Images/IconeContato1.png';
import IconeContato2 from '../../Images/IconeContato2.png';
import IconeContato3 from '../../Images/IconeContato3.png';

function Casa() {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');

  const clearFields = () => {
    setNome('');
    setEmpresa('');
    setTelefone('');
    setEmail('');
    setMensagem('');
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: '10px' }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;
    if (nome === '' || nome === undefined) {
      validaMenssages.push(<div key={'Nome'}>- Nome</div>);
      valida = true;
    }
    if (empresa === '' || empresa === undefined) {
      validaMenssages.push(<div key={'Empresa'}>- Empresa</div>);
      valida = true;
    }
    if (telefone === '' || telefone === undefined) {
      validaMenssages.push(<div key={'Telefone'}>- Telefone</div>);
      valida = true;
    }

    if (email === '' || email === undefined) {
      validaMenssages.push(<div key={'E-mail'}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div key={'E-mail'}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === '' || mensagem === undefined) {
      validaMenssages.push(<div key={'Mensagem'}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const data = JSON.stringify({
        nome: nome,
        empresa: empresa,
        telefone: telefone,
        email: email,
        mensagem: mensagem,
      });

      // useData()
      //   .postData('api/envia_contato.php', data)
      //   .then((response) => {
      //     console.log(response);
      //     Alert(
      //       <>
      //         <div>Formulário de contato enviado com sucesso!</div>
      //         <div>Em breve entraremos em contato.</div>
      //       </>,
      //     );
      //     clearFields();
      //     validaMenssages = [];
      //     setLoad(false);
      //   })
      //   .catch((err) => {
      //     setLoad(false);
      //     console.info(err);
      //   });

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        Nome: nome,
        Empresa: empresa,
        Telefone: telefone,
        Email: email,
        Mensagem: mensagem,
      });

      validaMenssages = [];

      fetch('https://appvillacountry.com.br:35000/contact_website_saopauloeventos', {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      })
        .then((response) => response.text())
        .then((response: any) => {
          Alert(
            <>
              <div className='TextMensagem3'>Formulário de contato enviado com sucesso!</div>
              <div className='TextMensagem4'>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          setLoad(false);
        })
        .catch((error) => console.log('error', error));
    }
  };
  return (
    <>
      <div className='Ancora' id='Contato'></div>
      <Load status={load} colorPrimary={'#c73127'} colorSecondary={'#b9b9b9'} />
      <S.ForaContato>
        <S.CimaContato>
          <S.EsqCimaContato>
            <S.TextCimaContato1>Contato</S.TextCimaContato1>
            <S.ForaItensContato>
              <S.ImagemRiscoContato src={ImagemRiscoContato} />
              <S.ItensContato>
                <S.ItemContato>
                  <S.IconteContato src={IconeContato1} />
                  <S.ForaTextItemContato>
                    <S.TextItemContato1>WHATSAPP</S.TextItemContato1>
                    <S.TextItemContato2>11 91622-0748</S.TextItemContato2>
                    <S.TextItemContato3>Informações sobre locações para eventos corporativos.</S.TextItemContato3>
                    <S.TextItemContato3>
                      * Este telefone não recebe ligações. Contato somente via WhatsApp.
                    </S.TextItemContato3>
                  </S.ForaTextItemContato>
                </S.ItemContato>
                <S.ItemContato>
                  <S.IconteContato src={IconeContato2} />
                  <S.ForaTextItemContato>
                    <S.TextItemContato1>Horário de Atendimento</S.TextItemContato1>
                    <S.TextItemContato2>SEG à SEX das 9h às 19h</S.TextItemContato2>
                  </S.ForaTextItemContato>
                </S.ItemContato>
                <S.ItemContato>
                  <S.IconteContato src={IconeContato3} />
                  <S.ForaTextItemContato>
                    <S.TextItemContato1>E-MAIL</S.TextItemContato1>
                    <S.TextItemContato2>Solicite seu orçamento: eventos@saopauloeventos.com.br</S.TextItemContato2>
                  </S.ForaTextItemContato>
                </S.ItemContato>
              </S.ItensContato>
            </S.ForaItensContato>
          </S.EsqCimaContato>

          <S.DirCimaContato>
            <S.FormContato>
              <S.Input
                type='text'
                name='c_nome'
                id='c_nome'
                placeholder='NOME *'
                onChange={(e) => setNome(e.target.value)}
                value={nome}
              />

              <S.Input
                type='text'
                name='c_empresa'
                id='c_empresa'
                placeholder='EMPRESA *'
                onChange={(e) => setEmpresa(e.target.value)}
                value={empresa}
              />

              <S.Input
                type='text'
                name='c_telefone'
                id='c_telefone'
                placeholder='TELEFONE *'
                onChange={(e) => setTelefone(e.target.value)}
                value={telefone}
              />

              <S.Input
                type='text'
                name='c_email'
                id='c_email'
                placeholder='E-MAIL *'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <S.TextArea
                name='c_mensagem'
                id='c_mensagem'
                placeholder='MENSAGEM *'
                onChange={(e) => setMensagem(e.target.value)}
                value={mensagem}
              />

              <S.BotaoEnvia type='button' onClick={() => onSubmit()}>
                Enviar
              </S.BotaoEnvia>
            </S.FormContato>
          </S.DirCimaContato>

          <S.ForaItensContatoResp>
            <S.ImagemRiscoContato src={ImagemRiscoContato} />
            <S.ItensContato>
              <S.ItemContato>
                <S.IconteContato src={IconeContato1} />
                <S.ForaTextItemContato>
                  <S.TextItemContato1>WHATSAPP</S.TextItemContato1>
                  <S.TextItemContato2>11 91622-0748</S.TextItemContato2>
                  <S.TextItemContato3>Informações sobre locações para eventos corporativos.</S.TextItemContato3>
                  <S.TextItemContato3>
                    * Este telefone não recebe ligações. Contato somente via WhatsApp.
                  </S.TextItemContato3>
                </S.ForaTextItemContato>
              </S.ItemContato>
              <S.ItemContato>
                <S.IconteContato src={IconeContato2} />
                <S.ForaTextItemContato>
                  <S.TextItemContato1>Horário de Atendimento</S.TextItemContato1>
                  <S.TextItemContato2>SEG à SEX das 9h às 19h</S.TextItemContato2>
                </S.ForaTextItemContato>
              </S.ItemContato>
              <S.ItemContato>
                <S.IconteContato src={IconeContato3} />
                <S.ForaTextItemContato>
                  <S.TextItemContato1>E-MAIL</S.TextItemContato1>
                  <S.TextItemContato2>Solicite seu orçamento: eventos@saopauloeventos.com.br</S.TextItemContato2>
                </S.ForaTextItemContato>
              </S.ItemContato>
            </S.ItensContato>
          </S.ForaItensContatoResp>
        </S.CimaContato>
      </S.ForaContato>
    </>
  );
}

export default Casa;
