import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

interface WhatsProps {
  isVisible?: boolean;
  number: string;
}


const WhatsApp = ({ isVisible = true, number }: WhatsProps) => {
  const urlWhats = 'https://wa.me/' + number;
      return (
        <>
          {isVisible &&
          <a
            id='rd-tqCy8gMKZ9neYRS8wX-DDw'
            aria-label='Abrir WhatsApp'
            className='bricks--floating--button rdstation-popup-js-floating-button shake focus-within'
            target='_blank'
            rel='noreferrer'
            href={urlWhats}></a>
          }
        </>
      );
};

export default WhatsApp;
