import React from 'react';
import * as S from './estilos';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import IconExpo from '../../Images/IconExpo.png';
import IconUnimed from '../../Images/IconUnimed.png';
import IconVilla from '../../Images/IconVilla.png';

const MandaVila = () => {
  window.open('http://www.gruposaopauloeventos.com.br/villacountry/', '_blank');
};

const MandaVilaShows = () => {
  window.open('https://villacountry.com.br/', '_blank');
};

const MandaExpo = () => {
  window.open('https://expobarrafunda.com.br/', '_blank');
};

const MandaUnimed = () => {
  window.open('http://www.gruposaopauloeventos.com.br/espacounimed/', '_blank');
};

const MandaUnimedShows = () => {
  window.open('https://espacounimed.com.br/', '_blank');
};

interface User {
  typeWeb?: boolean;
  link?: string;
  data: any;
}

const Banner = ({ data, link, typeWeb = false }: User) => {
  console.log(data.normal);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div id='Home'></div>
      <S.OutBanner>
        <S.ItemBanner>
          <S.OutItemBanner className='ItemBanner1'>
            <S.LogoBanner>
              <img src={IconExpo} />
            </S.LogoBanner>
            <div className='ForaBotoes'>
              <S.ButtonBanner onClick={() => MandaExpo()}>EVENTOS</S.ButtonBanner>
            </div>
          </S.OutItemBanner>
        </S.ItemBanner>
        <S.ItemBanner2>
          <S.OutItemBanner className='ItemBanner2'>
            <S.LogoBanner>
              <img src={IconUnimed} />
            </S.LogoBanner>
            <div className='ForaBotoes'>
              <S.ButtonBanner onClick={() => MandaUnimed()}>EVENTOS</S.ButtonBanner>
              <S.ButtonBanner onClick={() => MandaUnimedShows()}>SHOWS</S.ButtonBanner>
            </div>
          </S.OutItemBanner>
        </S.ItemBanner2>
        <S.ItemBanner3>
          <S.OutItemBanner className='ItemBanner3'>
            <S.LogoBanner>
              <img src={IconVilla} />
            </S.LogoBanner>
            <div className='ForaBotoes'>
              <S.ButtonBanner onClick={() => MandaVila()}>EVENTOS</S.ButtonBanner>
              <S.ButtonBanner onClick={() => MandaVilaShows()}>SHOWS</S.ButtonBanner>
            </div>
          </S.OutItemBanner>
        </S.ItemBanner3>
      </S.OutBanner>
    </>
  );
};

export default Banner;
