import React, { useEffect, useState } from 'react';
import * as S from './estilos';
import { Alert } from '../atoms';
import Load from '../Load';
import ImagemRiscoContato from '../../Images/ImagemRiscoContato.png';
import IconeContato1 from '../../Images/IconeContato1.png';
import IconeContato2 from '../../Images/IconeContato2.png';
import IconeContato3 from '../../Images/IconeContato3.png';

function Politica() {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');

  const clearFields = () => {
    setNome('');
    setEmpresa('');
    setTelefone('');
    setEmail('');
    setMensagem('');
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: '10px' }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;
    if (nome === '' || nome === undefined) {
      validaMenssages.push(<div key={'Nome'}>- Nome</div>);
      valida = true;
    }
    if (empresa === '' || empresa === undefined) {
      validaMenssages.push(<div key={'Empresa'}>- Empresa</div>);
      valida = true;
    }
    if (telefone === '' || telefone === undefined) {
      validaMenssages.push(<div key={'Telefone'}>- Telefone</div>);
      valida = true;
    }

    if (email === '' || email === undefined) {
      validaMenssages.push(<div key={'E-mail'}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div key={'E-mail'}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === '' || mensagem === undefined) {
      validaMenssages.push(<div key={'Mensagem'}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const data = JSON.stringify({
        nome: nome,
        empresa: empresa,
        telefone: telefone,
        email: email,
        mensagem: mensagem,
      });

      // useData()
      //   .postData('api/envia_contato.php', data)
      //   .then((response) => {
      //     console.log(response);
      //     Alert(
      //       <>
      //         <div>Formulário de contato enviado com sucesso!</div>
      //         <div>Em breve entraremos em contato.</div>
      //       </>,
      //     );
      //     clearFields();
      //     validaMenssages = [];
      //     setLoad(false);
      //   })
      //   .catch((err) => {
      //     setLoad(false);
      //     console.info(err);
      //   });

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        Nome: nome,
        Empresa: empresa,
        Telefone: telefone,
        Email: email,
        Mensagem: mensagem,
      });

      validaMenssages = [];

      fetch('https://appvillacountry.com.br:35000/contact_website_saopauloeventos', {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      })
        .then((response) => response.text())
        .then((response: any) => {
          Alert(
            <>
              <div className='TextMensagem3'>Formulário de contato enviado com sucesso!</div>
              <div className='TextMensagem4'>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          setLoad(false);
        })
        .catch((error) => console.log('error', error));
    }
  };
  return (
    <>
      <div className='Ancora' id='Contato'></div>
      <Load status={load} colorPrimary={'#c73127'} colorSecondary={'#b9b9b9'} />
      <S.ForaContato>
        <S.CimaContato>
          <S.EsqCimaContato>
            <S.TextCimaContato1>Política de Privacidade</S.TextCimaContato1>
            <S.ForaItensContato>
              #### Introdução <br />
              <br />
              Bem-vindo ao site do São Paulo Eventos. A sua privacidade é importante para nós. Esta política de
              privacidade descreve como coletamos, usamos e protegemos as informações pessoais fornecidas por você no
              nosso site [www.saopauloeventos.com.br](https://www.saopauloeventos.com.br/).
              <br />
              <br />
              #### Coleta de Informações <br />
              <br /> Coletamos informações pessoais que você nos fornece diretamente através de nossos canais de
              contato, como WhatsApp e e-mail. As informações podem incluir: <br />- Nome <br />- E-mail <br />- Número
              de telefone <br />- Informações necessárias para orçamentos e locação de eventos corporativos <br />
              <br />
              #### Uso das Informações <br />
              <br />
              As informações coletadas são utilizadas para: <br />
              <br />
              - Entrar em contato com você sobre eventos e locações <br />
              - Fornecer orçamentos e informações adicionais conforme solicitado <br />
              - Melhorar nossos serviços de atendimento ao cliente <br />
              <br />
              #### Compartilhamento de Informações
              <br />
              <br />
              Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para fornecer os
              serviços solicitados por você ou conforme exigido por lei.
              <br />
              <br />
              #### Segurança das Informações
              <br />
              <br />
              Empregamos medidas de segurança para proteger as informações pessoais que você nos fornece contra acesso
              não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma transmissão de dados pela
              internet ou sistema de armazenamento eletrônico é 100% seguro.
              <br />
              <br />
              #### Seus Direitos
              <br />
              <br />
              Você tem o direito de acessar, corrigir ou excluir suas informações pessoais armazenadas por nós. Para
              exercer esses direitos, entre em contato conosco através do e-mail: eventos@saopauloeventos.com.br.
              <br />
              <br />
              #### Alterações a Esta Política
              <br />
              <br />
              Podemos atualizar esta política de privacidade de tempos em tempos. Notificaremos você sobre quaisquer
              alterações publicando a nova política de privacidade no nosso site.
              <br />
              <br />
              #### Contato
              <br />
              <br />
              Se você tiver qualquer dúvida sobre esta política de privacidade ou desejar mais informações, entre em
              contato conosco:
              <br />
              <br />
              - WhatsApp: 11 91622-0748 (Contato somente via WhatsApp, este telefone não recebe ligações)
              <br />
              - E-mail: eventos@saopauloeventos.com.br
              <br />
              - Atendimento: Segunda a Sexta, das 9h às 19h
              <br />
              <br />
              ---
              <br />
              <br />
              Obrigado por visitar o São Paulo Eventos. Valorizamos sua confiança em nossos serviços e estamos
              comprometidos em proteger sua privacidade e informações pessoais.
              <br />
              <br />
              <br />
              <br />
              <br />
            </S.ForaItensContato>
          </S.EsqCimaContato>
        </S.CimaContato>
      </S.ForaContato>
    </>
  );
}

export default Politica;
