import styled from 'styled-components';
import ImgBgContato from '../../Images/BgContato.jpg';

export const ForaContato = styled.section`
  width: 1500px;
  margin: 0 auto;
  padding-top: 100px;

  @media (max-width: 1550px) {
    width: 1100px;
  }

  @media (max-width: 1150px) {
    width: 800px;
    padding-top: 40px;
  }

  @media (max-width: 850px) {
    width: 90%;
  }
`;

export const CimaContato = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const EsqCimaContato = styled.div`
  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const TextCimaContato1 = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #3b3b3b;
  text-transform: uppercase;
`;

export const ForaItensContato = styled.div`
  display: flex;
  margin-top: 70px;

  @media (max-width: 1550px) {
    margin-top: 25px;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const ForaItensContatoResp = styled.div`
  display: none;
  margin-top: 70px;

  @media (max-width: 1550px) {
    margin-top: 25px;
  }

  @media (max-width: 850px) {
    display: block;
    padding-bottom: 40px;
  }
`;

export const ImagemRiscoContato = styled.img`
  @media (max-width: 850px) {
    display: none;
  }
`;

export const ItensContato = styled.div`
  margin-left: -140px;

  @media (max-width: 1550px) {
    margin-left: -178px;
  }

  @media (max-width: 1150px) {
    margin-left: -198px;
  }

  @media (max-width: 850px) {
    margin-left: 0px;
  }
`;

export const ItemContato = styled.div`
  display: flex;
  align-items: center;

  :nth-child(2),
  :nth-child(3) {
    margin-top: 35px;
  }

  @media (max-width: 380px) {
    :nth-child(2),
    :nth-child(3) {
      margin-top: 15px;
    }
  }
`;

export const IconteContato = styled.img`
  @media (max-width: 1150px) {
    width: 45px;
  }

  @media (max-width: 380px) {
    width: 35px;
  }
`;

export const ForaTextItemContato = styled.div`
  padding-left: 25px;

  @media (max-width: 1150px) {
    padding-left: 15px;
  }
`;

export const TextItemContato1 = styled.h3`
  color: #3d3d3d;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 380px) {
    font-size: 18px;
  }
`;

export const TextItemContato2 = styled.h4`
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 400;
  padding-top: 7px;

  @media (max-width: 1150px) {
    font-size: 18px;
    padding-top: 0px;
  }

  @media (max-width: 380px) {
    font-size: 17px;
  }
`;

export const TextItemContato3 = styled.h4`
  color: #000;
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
`;


export const DirCimaContato = styled.div`
  width: 60%;

  @media (max-width: 1550px) {
    width: 53%;
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const BotaoEnvia = styled.button`
  font-weight: bold;
  font-size: 19px;
  background-color: #3b3b3b;
  text-transform: uppercase;
  border: 0px;
  width: 285px;
  height: 45px;
  color: #fff;
  border-radius: 12px;
  margin-top: 30px;
  cursor: pointer;

  @media (max-width: 400px) {
    width: 200px;
  }
`;

export const BaixoContato = styled.div`
  background-image: url(${ImgBgContato});
  background-size: cover;
  margin-top: -266px;

  @media (max-width: 850px) {
    margin-top: 0px;
  }
`;

export const ForaBaixoContato = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 1550px) {
    width: 1000px;
  }

  @media (max-width: 1150px) {
    width: 700px;
  }

  @media (max-width: 750px) {
    width: 90%;
    flex-wrap: wrap;
  }

  @media (max-width: 420px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const ItemBaixoContato = styled.div`
  width: 43%;
  background-color: #fff;
  padding-left: 5%;
  padding-bottom: 60px;
  border-radius: 10px;

  @media (max-width: 1550px) {
    padding-left: 4%;
    width: 44%;
  }

  @media (max-width: 1150px) {
    padding-bottom: 30px;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-top: 15px;
  }
`;

export const TextBaixoContato1 = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #b9b9b9;
  text-transform: uppercase;
  line-height: 24px;
  padding-top: 65px;

  @media (max-width: 1150px) {
    padding-left: 0%;
    font-size: 24px;
    padding-top: 25px;
  }
`;

export const TextBaixoContato2 = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.primary};
  text-transform: uppercase;

  @media (max-width: 1150px) {
    font-size: 24px;
  }
`;

export const ItensBaixoContato = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
`;

export const ItensBaixoContato2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  flex-direction: column;
`;

export const TextItensBaixoContato = styled.h4`
  font-weight: 400;
  font-size: 20px;
  color: #3d3d3d;
  width: 48%;
  margin-top: 5px;

  span {
    color: #c73127;
  }

  @media (max-width: 1150px) {
    font-size: 17px;
  }

  @media (max-width: 380px) {
    font-size: 16px;
  }
`;

export const TextItensBaixoContato2 = styled.h4`
  font-weight: 400;
  font-size: 20px;
  color: #3d3d3d;
  width: 100%;
  margin-top: 5px;

  span {
    color: #c73127;
    padding-right: 10px;
  }

  @media (max-width: 1150px) {
    font-size: 17px;
  }

  @media (max-width: 380px) {
    font-size: 16px;
  }
`;

export const BaixoItemContato = styled.div`
  display: flex;
  align-items: center;
  margin-top: 55px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const BaixoItemContato2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;

  @media (max-width: 1150px) {
    flex-wrap: wrap;
  } 

  @media (max-width: 1150px) {
    width: 100%;
    flex-wrap: wrap;
  } 
  
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const BotaoBaixarLista = styled.button`
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  width: 255px;
  height: 40px;
  background-color: ${({ theme }) => theme.color.primary};
  border: 0px;
  cursor: pointer;
  border-radius: 13px;

  @media (max-width: 1150px) {
    width: 200px;
    height: 40px;
    font-size: 14px;
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    width: 96%;
    margin-bottom: 15px;
  }
`;

export const ImagemPdf = styled.img`
  margin-left: 15px;
`;

// FORM

export const FormContato = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Input = styled.input`
  width: 98%;
  height: 55px;
  border: 2px solid #e2e2e2;
  margin-top: 15px;
  border-radius: 6px;
  padding-left: 2%;
  font-family: 'Noto Sans';
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
`;

export const TextArea = styled.textarea`
  width: 98%;
  height: 160px;
  border: 2px solid #e2e2e2;
  margin-top: 15px;
  padding-left: 2%;
  padding-top: 2%;
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
  border-radius: 6px;
  font-size: 16px;
`;
