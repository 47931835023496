import React from 'react';
import * as S from './estilos';
import ImgEstacionamento from '../../Images/ImgEstacionamento.jpg';
import ImgMapa from '../../Images/ImgMapa.jpg';
import ImgMapaResp from '../../Images/ImgMapaResp.jpg';
function Banner() {
  return (
    <>
      <S.ForaEsta id='Estacionamento'>
        <S.EsqEstac>
          <S.TextEsta1>estacionamento</S.TextEsta1>
          <S.TextEsta1Baixo>Com diversas opções</S.TextEsta1Baixo>
          <S.ForaItensEsta>
            <S.ItemEsta>
              <S.TextDirItemEstaResp>1</S.TextDirItemEstaResp>
              <S.EsqItemEsta>
                <S.TextItemEsta>PARX</S.TextItemEsta>
                <S.TextItemEsta2>Rua Julio Gonzales, 132</S.TextItemEsta2>
              </S.EsqItemEsta>
              <S.TextDirItemEsta>1</S.TextDirItemEsta>
            </S.ItemEsta>
            <S.ItemEsta>
              <S.TextDirItemEstaResp>2</S.TextDirItemEstaResp>
              <S.EsqItemEsta>
                <S.TextItemEsta>VIA +</S.TextItemEsta>
                <S.TextItemEsta2>Av. Francisco Matarazzo, 892</S.TextItemEsta2>
              </S.EsqItemEsta>
              <S.TextDirItemEsta>2</S.TextDirItemEsta>
            </S.ItemEsta>
            <S.ItemEsta>
              <S.TextDirItemEstaResp>3</S.TextDirItemEstaResp>
              <S.EsqItemEsta>
                <S.TextItemEsta>TREVO ESTACIONAMENTOS</S.TextItemEsta>
                <S.TextItemEsta2>Rua Tagipuru, 931</S.TextItemEsta2>
              </S.EsqItemEsta>
              <S.TextDirItemEsta>3</S.TextDirItemEsta>
            </S.ItemEsta>
            <S.ItemEsta>
              <S.TextDirItemEstaResp>4</S.TextDirItemEstaResp>
              <S.EsqItemEsta>
                <S.TextItemEsta>TREVO ESTACIONAMENTOS</S.TextItemEsta>
                <S.TextItemEsta2>Rua Tagipuru, 835 / Av. Francisco Matarazzo, 664</S.TextItemEsta2>
              </S.EsqItemEsta>
              <S.TextDirItemEsta>4</S.TextDirItemEsta>
            </S.ItemEsta>
            <S.ItemEsta>
              <S.TextDirItemEstaResp>5</S.TextDirItemEstaResp>
              <S.EsqItemEsta>
                <S.TextItemEsta>BRASIL PARK</S.TextItemEsta>
                <S.TextItemEsta2>Rua Tagipuru, 733</S.TextItemEsta2>
              </S.EsqItemEsta>
              <S.TextDirItemEsta>5</S.TextDirItemEsta>
            </S.ItemEsta>
          </S.ForaItensEsta>
        </S.EsqEstac>
        <S.DirEsta>
          <S.ImagemEstacionamento src={ImgEstacionamento} />
        </S.DirEsta>
      </S.ForaEsta>
    </>
  );
}

export default Banner;
