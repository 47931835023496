import styled from 'styled-components';
// import BgRodape from "../../Images/BgRodape.jpg";

export const BgFooter = styled.footer`
  background-color: #202020;
`;

export const ForaFooter = styled.div`
  width: 550px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: 610px) {
    flex-wrap: wrap;
    width: 484px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 510px) {
    flex-wrap: wrap;
    width: 90%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 410px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
`;

export const TextFooter1 = styled.h3`
  font-weight: 400;
  color: #f6f6f6;
  text-transform: uppercase;
  text-align: center;
`;

export const ForaImagensFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 540px) {
    flex-wrap: wrap;
  }
`;

export const ForaImgFooter = styled.div`
  cursor: pointer;

  @media (max-width: 540px) {
    width: 26%;
    margin: 0 auto;
  }

  @media (max-width: 420px) {
    width: 39%;
  }
`;

export const ImagemFooter = styled.img``;

export const BaixoFooter = styled.div`
  background-color: #000;
  margin-top: -2px;
  height: 95px;
  display: flex;
  align-items: center;
`;

export const ForaBaixoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
  margin: 0 auto;
  align-items: center;
`;

export const TextBaixoFooter = styled.h4`
  font-size: 12px;
  font-weight: 400;
  color: #f6f6f6;
  text-transform: uppercase;
`;

export const TextoPolitica = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`;
